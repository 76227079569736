export const STATS = [
    {
        value: '+10',
        label: 'estimate-project-stats.experience-label',
    },
    {
        value: '+100',
        label: 'estimate-project-stats.delivered-label',
    },
    {
        value: '+30',
        label: 'estimate-project-stats.experts-label',
    },
    {
        value: '3-6',
        suffix: 'estimate-project-stats.years',
        label: 'estimate-project-stats.cooperations-label',
    },
];
