import React from 'react';
import { BusinessIntelligenceView } from 'views/business-intelligence';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/services/business-intelligence.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const BusinessIntelligencePage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...caseStudiesMessages }}
        >
            <BusinessIntelligenceView {...{ faqData }} />
        </Layout>
    );
};

BusinessIntelligencePage.propTypes = {
    location: object.isRequired,
};

export default BusinessIntelligencePage;

export const Head = () => <SEO tags={TAGS.BUSINESS_INTELLIGENCE} />;

export const pageQuery = graphql`
    query BusinessIntelligencePageQuery {
        allContentfulFaq(
            filter: { slug: { eq: "services/business-intelligence" } }
        ) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
