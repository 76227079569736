import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { string } from 'prop-types';

import { STATS } from './constants';

const SContainer = styled.div`
    display: grid;
    column-gap: 2rem;
    row-gap: 4rem;
    grid-template-columns: repeat(4, 1fr);
    padding: 5rem var(--section-clamp);
    background-color: var(--gray-color-90);

    ${CONSTANTS.MEDIA.max1024`
    grid-template-columns: repeat(2, 1fr);
  `}

    ${CONSTANTS.MEDIA.max700`
    grid-template-columns: 1fr;
  `}
`;

const SStatContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const SValue = styled.div`
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    text-align: center;
`;

const SLabel = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.15rem;
    text-wrap: wrap;
    white-space: pre-wrap;
    max-width: 15rem;
`;

export const Stats = ({ className }) => {
    const { formatMessage } = useIntl();

    return (
        <SContainer className={className}>
            {STATS.map(({ label, value, suffix }, index) => (
                <SStatContainer key={index}>
                    <SValue>
                        {value}
                        {suffix && ` ${formatMessage({ id: suffix })}`}
                    </SValue>
                    <SLabel>{formatMessage({ id: label })}</SLabel>
                </SStatContainer>
            ))}
        </SContainer>
    );
};

Stats.propTypes = {
    className: string,
};
