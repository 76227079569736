import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { string } from 'prop-types';
import VerifiedIcon from 'svgs/icons/verified.svg';

const SWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: center;
`;

export const TextWithIcon = ({ textId }) => {
    return (
        <SWrapper>
            <VerifiedIcon />
            <FormattedMessage id={textId} />
        </SWrapper>
    );
};

TextWithIcon.propTypes = {
    textId: string.isRequired,
};
