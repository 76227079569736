import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { AnimatedBusinessIntelligence } from 'components/animated-business-intelligence';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { PATHS } from 'constants/paths';
import { FooterSection } from 'modules/footer-section';
import { MainSection } from 'modules/main-section';
import { QuoteBannerSection } from 'modules/quote-banner-section';
import { StatsSection } from 'modules/stats-section';
import { array } from 'prop-types';

import { CompanyYouTrustSection } from './company-you-trust-section';
import { LATEST_RELATED_ARTICLES_SETTINGS } from './constants';
import { CustomSolutionsSection } from './custom-solutions-section';
import { MainAdvantagesSection } from './main-advantages-section';
import { SolutionsSection } from './solutions-section';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

const Props = {
    faqData: array.isRequired,
};

export const BusinessIntelligenceView = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <div>
            <MainSection
                icon={<AnimatedBusinessIntelligence />}
                iconWrapperCss={css`
                    max-width: 34.25rem;
                `}
                titleId="view-business-intelligence.main-section.title"
                subtitles={[
                    formatMessage(
                        {
                            id: 'view-business-intelligence.main-section.subtitle',
                        },
                        {
                            link: (chunks) => (
                                <SPrefixLangLink to={PATHS.CASE_STUDIES}>
                                    {chunks}
                                </SPrefixLangLink>
                            ),
                        },
                    ),
                ]}
                estimateProjectAnalytics={
                    gaTrackedEvents.BUSINESS_INTELLIGENCE.CTA.ESTIMATE_PROJECT
                }
                intoCallAnalytics={
                    gaTrackedEvents.BUSINESS_INTELLIGENCE.CTA.INTRO_CALL
                }
                isSubtitleFormattedPreviously
            />
            <MainAdvantagesSection />
            <CustomSolutionsSection />
            <QuoteBannerSection
                quote="quotes.mobileRealityCompany"
                author="quotes.justynaSznajder"
                position="quotes.productManager"
            />
            <SolutionsSection />
            <CompanyYouTrustSection />
            <StatsSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </div>
    );
};

BusinessIntelligenceView.propTypes = Props;
