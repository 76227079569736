import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'components/container';
import { graphql, useStaticQuery } from 'gatsby';
import { RowWithTextImg } from 'modules/row-text-img';

export const CompanyYouTrustSection = () => {
    const { formatMessage } = useIntl();
    const trust = useStaticQuery(graphql`
        {
            image: file(
                relativePath: {
                    eq: "business-intelligence/trusted-company.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <Container>
            <RowWithTextImg
                title="view-business-intelligence.company-you-trust.title"
                descriptions={[
                    'view-business-intelligence.company-you-trust.paragraph',
                ]}
                img={trust.image}
                alt={formatMessage({
                    id: 'view-business-intelligence.company-you-trust.alt',
                })}
                isReversed={true}
            />
        </Container>
    );
};
