import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { GENRE } from 'constants/genre';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import ConnectionIcon from 'svgs/icons/ic-cloud-connection.svg';
import EaseIcon from 'svgs/icons/ic-data-vertical.svg';
import VisualisationIcon from 'svgs/icons/ic-document-filter.svg';
import AccessionIcon from 'svgs/icons/ic-monitor-mobile.svg';
import DepthIcon from 'svgs/icons/ic-search-data.svg';

export const TILES_DATA = [
    {
        icon: <ConnectionIcon width={42} height={42} />,
        text: 'view-business-intelligence.solutions-section.connection.title',
        description:
            'view-business-intelligence.solutions-section.connection.desc',
    },
    {
        icon: <EaseIcon width={42} height={42} />,
        text: 'view-business-intelligence.solutions-section.ease.title',
        description: 'view-business-intelligence.solutions-section.ease.desc',
    },
    {
        icon: <DepthIcon width={42} height={42} />,
        text: 'view-business-intelligence.solutions-section.depth.title',
        description: 'view-business-intelligence.solutions-section.depth.desc',
    },
    {
        icon: <VisualisationIcon width={42} height={42} />,
        text: 'view-business-intelligence.solutions-section.visualisation.title',
        description:
            'view-business-intelligence.solutions-section.visualisation.desc',
    },
    {
        icon: <AccessionIcon width={42} height={42} />,
        text: 'view-business-intelligence.solutions-section.accession.title',
        description:
            'view-business-intelligence.solutions-section.accession.desc',
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.BUSINESS_INTELLIGENCE],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'view-business-intelligence.footer.button',
    },
    categoryBanner: {
        title: 'view-business-intelligence.footer.title',
        description: 'view-business-intelligence.footer.description',
        svg: JavascriptSvg,
    },
};
