import { GENRE_KEY } from 'constants/genre-key';

export const GENRE = {
    ALL: {
        name: 'all',
        title: 'All',
        type: 'genre',
    },
    BUSINESS: {
        name: 'business',
        title: 'Business',
        type: 'genre',
    },
    WEB_DEVELOPMENT: {
        name: 'web_development',
        title: 'Web Development',
        type: 'genre',
    },
    MOBILE_DEVELOPMENT: {
        name: 'mobile_development',
        title: 'Mobile Development',
        type: 'genre',
    },
    MARKETING_HR: {
        name: 'marketing_and_hr',
        title: 'Marketing & HR',
        type: 'genre',
    },
    BUSINESS_INTELLIGENCE: {
        name: 'business_intelligence',
        title: 'Business Intelligence',
        type: 'genre',
    },
    ML_DATA_SCIENCE: {
        name: 'ml_data_science',
        title: 'ML & Data Science',
        type: 'genre',
    },
    REAL_ESTATE: {
        name: 'real-estate',
        title: 'Real Estate',
        type: 'genreExpertise',
    },
    STREAMING: {
        name: 'streaming',
        title: 'Streaming',
        type: 'genreExpertise',
    },
    HEALTHCARE: {
        name: 'healthcare',
        title: 'Healthcare',
        type: 'genreExpertise',
    },
    FINTECH: {
        name: 'fintech',
        title: 'Fintech',
        type: 'genreExpertise',
    },
    NFT_BLOCKCHAIN: {
        name: 'nft-blockchain',
        title: 'NFTs & Blockchain',
        type: 'genreExpertise',
    },
    JAVASCRIPT: {
        name: 'javascript',
        title: 'JavaScript',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    REACT_JS: {
        name: 'react-js',
        title: 'React.JS',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    NODE_JS: {
        name: 'node-js',
        title: 'Node.JS',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    VUE_JS: {
        name: 'vue-js',
        title: 'Vue.JS',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    REACT_NATIVE: {
        name: 'react-native',
        title: 'React Native',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    FLOW_CADENCE: {
        name: 'flow-cadence',
        title: 'Flow & Cadence',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    AWS: {
        name: 'aws',
        title: 'AWS',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    R: {
        name: 'r',
        title: 'R',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    GATSBY_JS: {
        name: 'gatsby-js',
        title: 'Gatsby.JS',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    NEXT_JS: {
        name: 'next-js',
        title: 'Next.JS',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    NEST_JS: {
        name: 'nest-js',
        title: 'Nest.JS',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
    PWA: {
        name: 'pwa',
        title: 'PWA',
        type: GENRE_KEY.GENRE_TECHNOLOGY,
        subType: GENRE_KEY.GENRE_TECHNOLOGIES,
    },
};
