import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'components/container';
import { graphql, useStaticQuery } from 'gatsby';
import { RowWithTextImg } from 'modules/row-text-img';

export const CustomSolutionsSection = () => {
    const { formatMessage } = useIntl();
    const solutions = useStaticQuery(graphql`
        {
            image: file(
                relativePath: {
                    eq: "business-intelligence/business-intelligence-solutions.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <Container>
            <RowWithTextImg
                title="view-business-intelligence.custom-solutions.title"
                descriptions={[
                    'view-business-intelligence.custom-solutions.paragraph',
                ]}
                img={solutions.image}
                alt={formatMessage({
                    id: 'view-business-intelligence.custom-solutions.alt',
                })}
            />
        </Container>
    );
};
